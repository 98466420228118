<template>
    <main>
      <div class="contact-section">
        <div class="container-fluid">
            <div class="contact-header">
              <div class="contact-content" v-html="DachziegelData.data.options.settings.app_kontakt_header">
              </div>
              <div class="contact-btn">
                 <a href="#" tag="button" class="btn btn-primary" @click.prevent="openUrl(DachziegelData.data.options.lang.contact_button_find_partner_url)">
                   {{DachziegelData.data.options.lang.contact_button_find_partner}}
                  </a>
              </div>
            </div>
            <div class="contact-teaser">
              <div v-for="(item, index) in DachziegelData.data.options.settings.app_kontakt_teaser" class="contact-teaser-card">
                <div class="card-inner">
                  <div class="card-image">
                      <img :src="getImgFromID(item.bild.id).url" class="img-fluid">
                  </div>
                  <div class="card-block">
                      <div class="card-text"  v-html="item.teaser">
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </main>
</template>
<script>
    import {DachziegelDataMixin} from "../mixins/DachziegelDataMixin";

    export default {
        name: 'contentKontakt',
        mixins: [DachziegelDataMixin],
        mounted() {
            this.$DKFuctions.headerShowGoBackHide()
        },
        methods: {
          getImgFromID: function (imgID) {
              return this.$DKFuctions.getImgFromID(imgID)
          },
          openUrl : function ($url) {
            window.open(encodeURI($url), '_system','location=yes');
          }
        }
    }
</script>
